document.addEventListener("DOMContentLoaded", function () {
    // Prevent closing from click inside dropdown
    document.querySelectorAll(".dropdown-menu").forEach(function (element) {
        element.addEventListener("click",
            function (e) {
                e.stopPropagation();
            });
    });


    // None of the below should be needed, it's re-written using CSS.

    //// Make it as accordion for smaller screens
    //if (window.innerWidth < 992) {
    //    // close all inner dropdowns when parent is closed
    //    document.querySelectorAll(".navbar .dropdown").forEach(function (everyDropdown) {

    //    // ReSharper disable once Html.EventNotResolved
    //    everyDropdown.addEventListener("hidden.bs.dropdown",
    //        function () {
    //            // after dropdown is hidden, then find all submenus
    //            this.querySelectorAll(".submenu").forEach(function (everySubmenu) {
    //                // hide every submenu as well
    //                everySubmenu.style.display = "none";
    //            });
    //        });
    //    });

    //    document.querySelectorAll(".dropdown-menu a").forEach(function (element) {
    //        element.addEventListener("click",
    //            function (e) {
    //                const nextEl = this.nextElementSibling;
    //                if (nextEl && nextEl.classList.contains("submenu")) {
    //                    // prevent opening link if link needs to open dropdown
    //                    e.preventDefault();
    //                    if (nextEl.style.display === "block") {
    //                        nextEl.style.display = "none";
    //                    } else {
    //                        nextEl.style.display = "block";
    //                    }
    //                }
    //            });
    //    });
    //}
    // End if innerWidth
});
// DOMContentLoaded end
